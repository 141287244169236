<script setup>
// import { ref } from 'vue';
import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Block Info</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Block Id</label>
                            <input class="form-control" v-model="model.block.blockid" id="input_block_name" type="text" placeholder="A01" disabled />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label text-danger">Block Nama</label>
                            <input class="form-control" v-model="model.block.blockname" id="input_block_name" type="text" placeholder="ACJ PL" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Relong</label>
                            <input class="form-control" v-model="model.block.relong" id="input_relong" type="number" placeholder="1.9999" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label text-danger">Quantity (PK)</label>
                            <input class="form-control" v-model="model.block.quantity" id="input_quantity" type="number" placeholder="100" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label text-danger">Umur</label>
                            <input type="number" id="input_age" v-model="model.block.age" class="form-control" >
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Next Potong Date</label>
                            <input class="form-control" v-model="model.block.nextdate" id="input_nextdate" type="date" />
                        </div>
                        <div class="col-md-6">
                            <label for="example-text-input" class="form-control-label text-danger">Lokasi</label>
                            <argon-input type="text" v-model="model.block.address" id="input_address" placeholder="213 Padng Lembu, 08330 Gurun Kedah" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Remark</label>
                            <argon-input type="text" id="input_remark" v-model="model.block.remark"  placeholder="Remark...." />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " @click="updateBlock" style="float: right;" id="btn_update_block">Update</button>
                        </div>
                    </div>
                    <h6>Payment Info</h6>
                    <div class="row ">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Term</label>
                            <input class="form-control" v-model="model.block.term" id="input_term" type="text" placeholder="One Month" disabled />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Amount</label>
                            <input class="form-control" v-model="model.block.amount" id="input_amount" type="number" placeholder="80.00" disabled />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Bank</label>
                            <input class="form-control" v-model="model.block.bank" id="input_bank" type="text" placeholder="Publick Bank" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Bank Acc</label>
                            <input class="form-control" v-model="model.block.bankacc" id="input_bankacc" type="number" placeholder="99999999999" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Name</label>
                            <input class="form-control" v-model="model.block.name" id="input_name" type="text" placeholder="Ang Wei Ze" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " @click="updateBlockPayment" style="float: right;" id="btn_update_blockpayment">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'blockCreate',
  data(){
    return {
        componentKey: 0,
    //   errorList: '',
      model:{
        block:{
            blockid:'',
            blockname:'',
            address:'',
            remark:'',
            relong:'',
            quantity:'',
            nextdate:'',
            age:'',
            term:'',
            amount:'',
            bank:'',
            bankacc:'',
            name:''
        },
        

      }
    }
  },
  mounted(){
    this.model.block.blockid = this.$route.params.id;
    this.getBlockDetailByID();
  },
  methods: {
    updateBlock(){
        // var mythis = this;
        console.log(this.model.block);
        axios.post('block/updateBlock',this.model.block)
            .then(res => {
                alert(res.data.message);
                
            }).catch(function (error){
                console.log('Error',error.message);
            });
    },
    updateBlockPayment(){
        // var mythis = this;
        axios.post('block/updateBlockPayment',this.model.block)
            .then(res => {
                alert(res.data.message);
            }).catch(function (error){
                console.log('Error',error.message);
            });
    },
    getBlockDetailByID(){
        // var mythis = this;
        axios.get('block/getBlockDetailByID/' + this.$route.params.id)
            .then(res => {
              console.log(res.data.block[0]);
                this.model.block = res.data.block[0];
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Name, Company, Contact, State and Address is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>