<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Sawit Buah table</h6>
        <div class="row">
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Block ID</label>
                <input class="form-control" v-model="search.blockid" id="input_blockid" type="text" placeholder="A01" />
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date From</label>
                <input type="date" id="input_datefrom" class="form-control" v-model="search.datefrom">
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date To</label>
                <input type="date" id="input_dateto" class="form-control" v-model="search.dateto">
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label" style="width: 100%;">&nbsp;</label>
                <button class="btn btn-success " style="float:right;" id="btn_search" @click="getSalesBuahList">Search</button>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-6">
                <label for="example-text-input" class="form-control-label">Total Weight: {{ this.ttlSalesBuah.ttl_weight }} Tonne</label>
            </div>
            <div class="col-md-6">
                <label for="example-text-input" class="form-control-label">Est Sales: {{ formattedTtlPrice }}</label>
            </div>
        </div>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Block
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Payment
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Details
                </th>
                <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Date
                </th>
                <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Remark
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(salesBuah,index) in this.salesBuah" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ salesBuah.blockid }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ salesBuah.blockname}}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">{{ salesBuah.price }} RM/T</h6>
                  <p class="text-xs text-secondary mb-0">{{ salesBuah.sellto }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ salesBuah.weight }} Tonne</p>
                  <p class="text-xs text-secondary mb-0">{{ salesBuah.quantity }} Biji</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">Sell: {{ salesBuah.sell_at }}</p>
                  <p class="text-xs font-weight-bold mb-0">Price: MYR {{ salesBuah.estprice }}</p>
                </td>
                <td>
                  <p class="text-xs font-weight-bold mb-0">{{ salesBuah.remark }}</p>
                </td>
                <td class="align-middle">
                  <RouterLink :to="{path : '/editBlock/'+ salesBuah.blockid}"
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Edit Block"
                    >Edit
                  </RouterLink>
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>

  
<script>
import axios from 'axios';

export default {
  name:'salesBuahSelect',
  data(){
    return {
      formatLang: 'en-EN',
      formatStyle: 'currency',
      formatCurrency: 'MYR',
      salesBuah: [],
      ttlSalesBuah: {
        ttl_quantity: '0',
        ttl_weight: '0',
        ttl_price: '0'
      },
      search:{
          dateto: new Date().toISOString().slice(0,10),
          datefrom: new Date(this.currentDate()).toISOString().slice(0,10),
          blockid: '',
        }
    }
  },
  computed: {
    formatter() {
      return new Intl.NumberFormat(this.formatLang, {
        style: this.formatStyle,
        currency: this.formatCurrency
      })
    },
    formattedTtlPrice() {
      return this.formatter.format(this.ttlSalesBuah.ttl_price)
    }
  },
  mounted(){
    this.getSalesBuahList();
  },
  methods: {

    currentDate() {
      const firstDayMonth = new Date();
      const date = `${firstDayMonth.getMonth()+1}/02/${firstDayMonth.getFullYear()}`;
      return date;
    },
    getSalesBuahList(){
      var blockid = '%';
        if(this.search.blockid != ''){
            blockid = this.search.blockid;
        }
        axios.get('block/listSalesBuah/' + this.search.datefrom + "/" + this.search.dateto + "/" +  blockid)
            .then(res => {
              console.log(res.data.salesBuah);
              this.salesBuah = res.data.salesBuah;
              this.ttlSalesBuah.ttl_quantity= res.data.ttlSalesBuah[0].ttl_quantity;
              this.ttlSalesBuah.ttl_weight= res.data.ttlSalesBuah[0].ttl_weight;
              this.ttlSalesBuah.ttl_price= res.data.ttlSalesBuah[0].ttl_price;
            }).catch(function (error){
                
              console.log('Error',error.message);
            });
    }
  },
}
</script>
  