<script setup>
// import { ref } from 'vue';
import BlockExpensesTable from "./Tables/BlockExpensesTable.vue";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Expenses</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Block Id</label>
                            <input class="form-control" v-model="model.expenses.blockid" id="input_block_id" type="text" placeholder="A01" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Type</label>
                            <select class="form-control" v-model="model.expenses.type">
                                <option value="1">Baja</option>
                                <option value="2">Gaji</option>
                                <option value="3">Racun</option>
                                <option value="4">Transport</option>
                                <option value="5">One time cost (Cuci Kebun ...)</option>
                                <option value="6">Maintainance</option>
                                <option value="7">Cuci Daun</option>
                                <option value="9">Others</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Date</label>
                            <input type="date" id="input_date" v-model="model.expenses.date" class="form-control" >
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Item</label>
                            <input class="form-control" v-model="model.expenses.item" id="input_term" type="text" placeholder="Baja Urea" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Price</label>
                            <input class="form-control" v-model="model.expenses.price" id="input_amount" type="number" placeholder="80.00" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Is Paid (Baja Only)</label>
                            <select name="select_ispay" id="select_ispay" @change="changeRemark($event.target.value)" v-model="model.expenses.ispaid" class="form-control">
                                <option value="1">New (buy from someone)</option>
                                <option value="0">Is Paid (Nursery Sudah bayar)</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label for="example-text-input" class="form-control-label">Payment Remark (Baja Only)</label>
                            <input class="form-control" v-model="model.expenses.remark" id="input_bankacc" type="text" placeholder="Payment Remark..." />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " @click="addExpenses" style="float: right;" id="btn_add">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-4 row">
        <div class="col-12">
            <BlockExpensesTable :key="componentKey" />
        </div>
    </div>
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'bajaCreate',
  data(){
    return {
        componentKey: 0,
    //   errorList: '',
      model:{
        expenses:{
            blockid:'',
            type:'1',
            date: '',
            remark:'Beli dari orang kampong',
            item:'',
            price:'0',
            ispaid:'1'
        },

      }
    }
  },
  methods: {
    changeRemark(value){
        if(value == '1'){
            this.model.expenses.remark = 'Beli dari orang kampong';
        }else{
            this.model.expenses.remark = 'ACJ nursery sudah paid';
        }

    },
    addExpenses(){
        console.log(this.model.expenses);
        axios.post('expenses/addExpensesBlock',this.model.expenses)
            .then(res => {
                alert(res.data.message);
                this.model.expenses = {
                    blockid:'',
                    type:'0',
                    remark:'Beli dari orang kampong',
                    item:'baja',
                    price:'0',
                    ispaid:'1'
                };
                
                this.componentKey += 1;
            }).catch(function (error){

                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Block ID, Quantity and Date is required! ');
                    }else{
                        console.log(error.response.data.message);
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>