<script setup>
// import { ref } from 'vue';
import BlockTable from "./Tables/BlockTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Block Info</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label text-danger">Block Id</label>
                            <input class="form-control" v-model="model.block.blockid" id="input_block_id" type="text" placeholder="A01" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label text-danger">Block Nama</label>
                            <input class="form-control" v-model="model.block.blockname" id="input_block_name" type="text" placeholder="ACJ PL" />
                        </div>
                        <!-- <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Relong</label>
                            <input class="form-control" v-model="model.block.relong" id="input_relong" type="number" placeholder="1.9999" />
                        </div> -->
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Quantity (PK)</label>
                            <input class="form-control" v-model="model.block.quantity" id="input_quantity" type="number" placeholder="100" />
                        </div>
                        <!-- <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Estimate (Tonne)</label>
                            <input class="form-control" v-model="model.block.est_kg" id="input_est_kg" type="number" placeholder="1.0" />
                        </div> -->
                        <div class="col-md-6">
                            <label for="example-text-input" class="form-control-label">Lokasi</label>
                            <argon-input type="text" v-model="model.block.address" id="input_address" placeholder="213 Padng Lembu, 08330 Gurun Kedah" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label text-danger">Umur</label>
                            <argon-input type="number" v-model="model.block.age" id="age" placeholder="age of tree" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label text-danger">Next Potong Date</label>
                            <input type="date" id="input_date" v-model="model.block.nextdate" class="form-control" >
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Remark</label>
                            <argon-input type="text" id="input_remark" v-model="model.block.remark"  placeholder="Remark...." />
                        </div>
                    </div>
                    <h6>Payment Info</h6>
                    <div class="row ">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label text-danger">Term</label>
                            <input class="form-control" v-model="model.block.term" id="input_term" type="text" placeholder="One Month" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label text-danger">Amount</label>
                            <input class="form-control" v-model="model.block.amount" id="input_amount" type="number" placeholder="80.00" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Bank</label>
                            <input class="form-control" v-model="model.block.bank" id="input_bank" type="text" placeholder="Publick Bank" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Bank Acc</label>
                            <input class="form-control" v-model="model.block.bankacc" id="input_bankacc" type="number" placeholder="99999999999" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Name</label>
                            <input class="form-control" v-model="model.block.name" id="input_name" type="text" placeholder="Ang Wei Ze" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " @click="addBlock" style="float: right;" id="btn_add">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-4 row">
        <div class="col-12">
            <BlockTable :key="componentKey" />
        </div>
    </div>
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'blockCreate',
  data(){
    return {
        componentKey: 0,
    //   errorList: '',
      model:{
        block:{
            blockid:'',
            blockname:'',
            address:'',
            remark:'',
            relong:'',
            quantity:'',
            nextdate:'',
            age:'',
            term:'',
            amount:'',
            bank:'',
            bankacc:'',
            name:''
        },
        

      }
    }
  },
  methods: {
    addBlock(){
        // var mythis = this;
        axios.post('block/add',this.model.block)
            .then(res => {
                alert(res.data.message);
                this.model.block = {
                    blockname:'',
                    address:'',
                    remark:'',
                    relong:'',
                    quantity:'',
                    est_kg:'',
                    age:'',
                    blockid: '',
                    term:'',
                    amount:'',
                    bank:'',
                    bankacc:'',
                    name:''
                };
                this.componentKey += 1;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Block Name, ID, Payment Term and Amount is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>