<template>
    <div class="card">
      <div class="card-header pb-0">
        <h6>Sewa Pajak</h6>
        <div class="row">
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date From</label>
                <input type="date" id="input_date" class="form-control" v-model="search.datefrom" >
            </div>
            <div class="col-md-3">
                <label for="example-text-input" class="form-control-label">Date To</label>
                <input type="date" id="input_date" class="form-control" v-model="search.dateto" >
            </div>
            <div class="col-md-5">
            </div>
            <div class="col-md-1">
                <label for="example-text-input" class="form-control-label">&nbsp;</label>
                <button class="btn btn-success " @click="getSewaPajak" style="float: right;" id="btn_search">Search</button>
            </div>
        </div>
      </div>
      <hr />
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Block
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Amount
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Reference
                </th>
                <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Remark
                </th>
                <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  Date
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sewaPajak,index) in this.sewaPajak" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ sewaPajak.blockid }} - {{ sewaPajak.blockname }} <br />{{ sewaPajak.remark }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">{{ sewaPajak.amount }}</h6>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">{{ sewaPajak.description }}</h6>
                </td>
                <td>
                  <h6 class="text-xs font-weight-bold mb-0">{{ sewaPajak.date }}</h6>
                </td>
                <td class="align-middle">
                  <RouterLink :to="{path : '/editBlock/'+ sewaPajak.blockid}"
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Edit Block"
                    >Remove
                  </RouterLink>
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>

  
<script>
import axios from 'axios';

export default {
  name:'blockSelect',
  data(){
    return {
      sewaPajak: [],
      search:{
        dateto: new Date().toISOString().slice(0,10),
        datefrom: new Date(this.currentDate()).toISOString().slice(0,10),
      }
    }
  },
  mounted(){
    this.getSewaPajak();
  },
  methods: {
    currentDate() {
      const firstDayMonth = new Date();
      const date = `${firstDayMonth.getMonth()+1}/02/${firstDayMonth.getFullYear()}`;
      return date;
    },
    getSewaPajak(){
      axios.get('block/listSewaPajak/' + this.search.datefrom + '/' + this.search.dateto)
            .then(res => {
              // console.log(res.data.sewaPajak);
              this.sewaPajak = res.data.sewaPajak;
            }).catch(function (error){
                
              console.log('Error',error.message);
            });
    }
  },
}
</script>
  