<script setup>
// import { ref } from 'vue';
import SewaPajakTable from "./Tables/SewaPajakTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Sewa Pajak</h6>
                </div>
                <div class="py-4 container-fluid">
                    <div class="row ">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label text-danger">Block Id</label>
                            <input class="form-control" v-model="model.sewaPajak.blockid" id="input_block_id" type="text" placeholder="A01,A02,A03" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label text-danger">Amount</label>
                            <input class="form-control" v-model="model.sewaPajak.amount" id="input_amount" type="number" placeholder="80.00" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label text-danger">Date</label>
                            <input type="date" id="input_date" v-model="model.sewaPajak.dateInv" class="form-control">
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Remark</label>
                            <argon-input type="text" id="input_remark" v-model="model.sewaPajak.remark"  placeholder="Remark...." />
                        </div>
                    </div>
                    <!-- <div class="row ">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Term</label>
                            <input class="form-control" v-model="model.block.term" id="input_term" type="text" placeholder="One Month" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Bank</label>
                            <input class="form-control" v-model="model.block.bank" id="input_bank" type="text" placeholder="Publick Bank" />
                        </div>
                        <div class="col-md-2">
                            <label for="example-text-input" class="form-control-label">Bank Acc</label>
                            <input class="form-control" v-model="model.block.bankacc" id="input_bankacc" type="number" placeholder="99999999999" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Name</label>
                            <input class="form-control" v-model="model.block.name" id="input_name" type="text" placeholder="Ang Wei Ze" />
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " @click="addSewaPajak" style="float: right;" id="btn_add">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="mt-4 row">
        <div class="col-12">
            <SewaPajakTable :key="componentKey" />
        </div>
    </div>
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'AddSewaPajak',
  data(){
    return {
        componentKey: 0,
    //   errorList: '',
      model:{
        sewaPajak:{
            blockid:'',
            remark:'',
            dateInv: new Date().toISOString().slice(0,10),//
            amount:'0'
        },
        

      }
    }
  },
  methods: {
    addSewaPajak(){
        // var mythis = this;
        // console.log(this.model.sewaPajak);
        axios.post('block/addSewaPajak',this.model.sewaPajak)
            .then(res => {
                alert(res.data.message);
                this.model.sewaPajak = {
                    blockid:'',
                    remark:'',
                    dateInv:'',
                    amount:''
                };
                this.componentKey += 1;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Block id, Date, and Amount is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    }
    
  },
}
</script>