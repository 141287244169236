<script setup>
// import { ref } from 'vue';
import SawitBuahTable from "./Tables/SawitBuahTable.vue";
// import SawitBuahScheduleTable from "./Tables/SawitBuahScheduleTable.vue";
import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
</script>
<template>
<div class="py-4 container-fluid ">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-0">
                <h6>Potong Sawit Buah</h6>
                </div>
                <div class="container-fluid">
                    <div class="row ">
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label text-danger">Block Id(s)</label>
                            <input class="form-control" v-model="model.sawitbuah.blockid" id="input_block_name" type="text" placeholder="A01,A02,A03" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label text-danger">Weight (Tonne)</label>
                            <input class="form-control" v-model="model.sawitbuah.weight" id="input_weight" type="number" placeholder="1" />
                        </div>
                        <!-- <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Quantity (Biji)</label>
                            <input class="form-control" v-model="model.sawitbuah.quantity" id="input_quantity" type="number" placeholder="200" />
                        </div> -->
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label text-danger">Date</label>
                            <input type="date" id="input_date" v-model="model.sawitbuah.sell_date" class="form-control" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Price (Est.)(RM)</label>
                            <input class="form-control" v-model="model.sawitbuah.price" id="input_price" type="number" placeholder="750" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Sell To</label>
                            <argon-input type="text" v-model="model.sawitbuah.sellto" id="input_sellto" placeholder="Ican Bukit Kuang" />
                        </div>
                        <div class="col-md-3">
                            <label for="example-text-input" class="form-control-label">Remark</label>
                            <argon-input type="text" id="input_remark" v-model="model.sawitbuah.remark"  placeholder="Remark...." />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-success " @click="addSawitBuah" style="float: right;" id="btn_add">Add</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4 row">
        <div class="col-md-12">
            <SawitBuahTable :key="componentKey" />
        </div>
    </div>
    <!-- <div class="mt-4 row">
        <div class="col-md-6">
            <SawitBuahScheduleTable :key="sawitBuahScheduleKey" />
        </div>
    </div> -->
</div>
</template>


<script>
import axios from 'axios';

export default {
  name:'sawitBuahCreate',
  data(){
    return {
        // image:'',
        componentKey: 0,
        // sawitBuahScheduleKey: 0,
    //   errorList: '',
      model:{
        sawitbuah:{
            blockid:'',
            weight:'',
            remark:'',
            price:'',
            quantity:'0',
            sellto:'Ican Bukit Kuang',
            sell_date: new Date().toISOString().slice(0,10),
        },
      },
      blockList:[],
      selectedBlock:[],
      rawDate:'',
      options: [
        { value: "a1", text: "Option 1" },
        { value: "a2", text: "Option 2" },
        { value: "a3", text: "Option 3" },
        { value: "a4", text: "Option 4" },
      ],
      selectedOptions: [], // Stores selected objects
    }
  },
  mounted(){
    this.listAllBlock();
  },
  methods: {
    addSawitBuah(){
        // var mythis = this;
        // console.log(JSON.stringify(this.model.sawitbuah));
        axios.post('block/addSawitBuahSales',this.model.sawitbuah)
            .then(res => {
                alert(res.data.message);
                // this.uploadImage();
                this.model.sawitbuah = {
                    blockid:'',
                    weight:'',
                    remark:'',
                    price:'',
                    quantity:'0',
                    sellto:'Ican Bukit Kuang"',
                    sell_date: new Date().toISOString().slice(0,10)
                };
                this.componentKey += 1;
                this.sawitBuahScheduleKey += 1;
            }).catch(function (error){
                if(error.response){
                    if(error.response.status == 422){
                        alert('Error: Block id, Weight and Date is required! ');
                    }
                }else if(error.request){
                    console.log(error.request);
                }else{
                    console.log('Error',error.message);
                }
            });
    },
    listAllBlock(){
        this.blockList = [];
        axios.get('block/getBlockidList')
            .then(res => {
            // console.log(res.data.blockidList,"Success");
            res.data.blockidList.forEach(element => {
                var blockObj = {
                    "text": element.blockid, "value":element.blockname
                }
                this.blockList.push(blockObj);
                console.log(this.blockList);
              });
            }).catch(function (error){
                
              console.log('Error',error.message);
            });
    }
    
  },
}
</script>